/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
// import { PropTypes } from "prop-types";

import React from "react";
import { graphql } from "gatsby";
import { CollectionBuilder, Layout, SEO, StructuredData } from "~components";
import { CollectionBanner } from "~components/PageBuilder/sections";

/** ============================================================================
 * @component
 * Core template for static site paths.
 */
const Collection = ({ data, location }) => {
  const cms = data.sanityCollection;

  const {
    primaryColour,
    secondaryColour,
    tertiaryColour,
    heading,
    subText,
    button,
    objectType,
    pagebuilder: { sections }
  } = cms;

  const seoProps = {
    customTitle: cms?.seoTitle ? cms.seoTitle : cms.name,
    customDescription: cms?.seoDescription,
    customKeywords: cms?.seoKeywords,
    path: location.pathname
  };

  //

  return (
    <>
      <SEO {...seoProps} />
      <StructuredData sections={sections} {...seoProps} />

      <Layout>
        <CollectionBanner
          data={{
            primaryColour,
            secondaryColour,
            tertiaryColour,
            heading,
            subText,
            button,
            objectType
          }}
          path={location.pathname}
        />
        {cms?.pagebuilder?.sections?.[0] && (
          <CollectionBuilder pagebuilder={cms.pagebuilder} />
        )}
      </Layout>
    </>
  );
};

export default Collection;

export const query = graphql`
  query Collection($id: String!) {
    sanityCollection(id: { eq: $id }) {
      name

      primaryColour {
        hex
        title
      }

      secondaryColour {
        hex
        title
      }

      tertiaryColour {
        hex
        title
      }

      heading

      subText

      button {
        text
        link
      }

      objectType

      ...CollectionBuilderFragment

      seoTitle
      seoDescription
      seoKeywords
    }
  }
`;
